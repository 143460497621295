<template>
  <div class="mt-4 pl-4 pr-4">
    <vs-tabs color="warning">
      <vs-tab label="Resumo da Proposta">
        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Responsável</p>
              <vs-select v-model="selectedOwnerCard" @change="atualizarResponsavel">
                <vs-select-item
                  v-for="(item, index) in ownerList"
                  :key="index"
                  :value="item"
                  :text="item"
                />
              </vs-select>
            </div>

            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold mb-1">Prioridade</p>
              <vs-select v-model="selectedPriorityId" @change="atualizarPrioridade">
                <vs-select-item
                  v-for="(item, index) in cardPriorityList"
                  :key="index"
                  :value="item.Id"
                  :text="item.Name"
                />
              </vs-select>
            </div>
          </div>

          <div class="vx-row mt-4" v-if="deveExibirResponsavelMovimentacao">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">{{ stepName }} por</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).Name ? (cardMovementUser || {}).Name : "-" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data da movimentação</p>
              <p class="text-lg">
                {{ (cardMovementUser || {}).MovingDate ? (cardMovementUser || {}).MovingDate : "-" | moment("DD/MM/YYYY HH:mm") }}
              </p>
            </div>
          </div>

        </vs-card>
        
        <vs-card class="mt-4 p-8 dados-proposta" v-if="exibirJustificativaMovimentacaoCard"> 
          <div class="vx-row">
            <div class="vx-col w-full">
              <p class="text-sm font-semibold">
                Justificativa para movimentação do card <span v-if="justificativaMovimentacaoCardObrigatoria">(obrigatório)</span>:
              </p>
              <vs-textarea 
                class="mt-4" 
                v-model="justification" 
                :disabled="!podeModificarJustificativaMovimentacaoCard"
              />
            </div>
            <div class="vx-col w-full text-right">
              <vs-button
                :disabled="!podeModificarJustificativaMovimentacaoCard"
                @click.native="salvarJustificativa()"
                color="primary"
              >
                <span class="font-semibold pr-2 pl-2">
                  Salvar
                </span>
              </vs-button>
            </div>
          </div>
        </vs-card>

        <vs-card class="mt-4 p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Nroº Proposta</p>
              <p class="text-lg">{{ proposta.Number || "Não informado" }}</p>
            </div>
          </div>
          <div class="vx-row mb-6 mt-2">
            <div class="vx-col w-1/2"></div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Data de Cadastro</p>
              <p class="text-lg">
                {{ proposta.CreateDate | moment("DD/MM/YYYY HH:mm") }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Corretora</p>
              <p class="text-lg">{{ (proposta.Broker || {}).Name }}</p>
              <small>{{ (proposta.Broker || {}).CpfCnpj | maskCpfCnpj }}</small>
            </div>
          </div>
        </vs-card>

        <vs-card class="p-8 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Tomador</p>
              <p class="text-lg">
                {{ proposta.PolicyHolder.Name || "Não informado" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Cnpj</p>
              <p class="text-lg">
                {{
                  (proposta.PolicyHolder.CpfCnpj || "Não informado")
                    | maskCpfCnpj
                }}
              </p>
            </div>
          </div>
          <div class="vx-row mb-6 mt-2">
            <div class="vx-col w-1/2">
              <router-link
                :to="{
                  name: 'detalhe-tomador',
                  params: { tomadorUniqueId: proposta.PolicyHolder.UniqueId }
                }"
                target="_blank"
              >
                <span class="font-semibold">
                  Visualizar cadastro completo do tomador
                </span>
              </router-link>
            </div>
          </div>
          <div class="vx-row mt-6">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Segurado</p>
              <p class="text-lg">
                {{ proposta.Insured.Name || "Não informado" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Cpf/Cnpj</p>
              <p class="text-lg">
                {{
                  (proposta.Insured.CpfCnpj || "Não informado") | maskCpfCnpj
                }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card
          class="mt-4 p-8"
          v-if="
            !proposta.PolicyHolder.EconomicGroupId &&
              cardType === 'GRUPO_ECONOMICO'
          "
        >
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-alert color="warning" class="h-auto">
                Tomador sem Grupo econômico definido. Digite o documento do
                grupo econômico e clique em salvar.
              </vs-alert>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col w-full">
              <vs-input
                label="Documento do Grupo econômico"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                v-model="grupoEconomico.CpfCnpj"
                size="large"
                class="w-full"
              />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col text-right w-full mt-2">
              <vs-button @click.native="salvarGrupoEconomico()" color="primary">
                <span class="font-semibold pr-2 pl-2">
                  Atualizar Grupo econômico
                </span>
              </vs-button>
            </div>
          </div>
        </vs-card>

        <vs-card class="p-8 pt-4 pb-4 dados-proposta">
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Grupo Principal</p>
              <p class="text-lg">
                {{ proposta.ModalityGroup.Name || "Não informado" }}
              </p>
            </div>
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Modalidade Principal</p>
              <p class="text-lg">
                {{ proposta.Modality.Name || "Não informado" }}
                <b style="color:red">
                  {{
                    proposta.Modality.IsDeleted ? "(Modalidade Inativada)" : ""
                  }}</b
                >
              </p>
            </div>
          </div>

          <div class="vx-row mt-6">
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Importância Segurada</p>
              <p class="text-lg">
                {{ proposta.InsuredAmountValue | maskCurrency }}
              </p>
            </div>
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Inicio Vigência</p>
              <p class="text-lg">
                {{ proposta.StartDate | moment("DD/MM/YYYY") }}
              </p>
            </div>
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Fim Vigência</p>
              <p class="text-lg">
                {{ proposta.EndDate | moment("DD/MM/YYYY") }}
              </p>
            </div>
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Prazo</p>
              <p class="text-lg">{{ proposta.DeadlineDays }} dias</p>
            </div>
          </div>
        </vs-card>

        <vs-card
          class="p-8 pt-4 pb-4 dados-proposta"
          v-if="proposta.ComplementaryModality.Id"
        >
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <p class="text-sm font-semibold">Modalidade Complementar</p>
              <p class="text-lg">
                {{ proposta.ComplementaryModality.Name || "Não informado" }}
              </p>
            </div>
          </div>

          <div class="vx-row mt-6">
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Importância Segurada</p>
              <p class="text-lg">
                {{ proposta.ComplementaryInsuredAmountValue | maskCurrency }}
              </p>
            </div>
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Inicio Vigência</p>
              <p class="text-lg">
                {{ proposta.ComplementaryStartDate | moment("DD/MM/YYYY") }}
              </p>
            </div>
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Fim Vigência</p>
              <p class="text-lg">
                {{ proposta.ComplementaryEndDate | moment("DD/MM/YYYY") }}
              </p>
            </div>
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Prazo</p>
              <p class="text-lg">
                {{ proposta.ComplementaryDeadlineDays }} dias
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card
          class="p-8 pt-4 pb-4 dados-proposta"
          v-if="proposta.ObjectValue"
        >
          <div class="vx-row">
            <div class="vx-col w-1/1">
              <p class="text-sm font-semibold">Objeto</p>
              <p class="text-lg mt-4">
                {{ proposta.ObjectValue || "Não informado" }}
              </p>
            </div>
          </div>
        </vs-card>

        <vs-card
          class="p-8 pt-4 pb-4 dados-proposta"
          v-if="proposta.ContractualTerm"
        >
          <div class="vx-row">
            <div class="vx-col w-1/1">
              <p class="text-sm font-semibold">Clausulado</p>
              <p class="text-lg mt-4" v-html="proposta.ContractualTerm"></p>
            </div>
          </div>
        </vs-card>

        <vs-card class="p-8 pt-4 pb-4 dados-proposta">
          <div class="vx-row mt-6">
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Prêmio</p>
              <p class="text-lg">
                {{ proposta.InsurancePremium | maskCurrency }}
              </p>
            </div>
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Taxa</p>
              <p class="text-lg">{{ proposta.RiskRate }}%</p>
            </div>

            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">Valor da Comissão</p>
              <p class="text-lg">
                {{ proposta.ComissionValue | maskCurrency }}
              </p>
            </div>
            <div class="vx-col w-1/4">
              <p class="text-sm font-semibold">% Comissão</p>
              <p class="text-lg">{{ proposta.ComissionPercentage }}%</p>
            </div>
          </div>

          <div class="vx-row mt-6" v-if="cardType === 'PROPOSTA'">
            <div class="vx-col text-right w-full mt-2">
              <router-link
                :to="{
                  name: 'resumo-proposta-visao-seguradora',
                  params: {
                    propostaUniqueId: this.proposta.UniqueId,
                    proposalType: 'Proposta'
                  }
                }"
                target="_blank"
              >
                <vs-button
                  color="primary"
                  v-if="
                    perfilHelpers.checkPermission([
                      perfilHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_PROPOSTAS_SEGURADORA,
                      perfilHelpers.menuFuncionalitiesEnum
                        .VISUALIZAR_PROPOSTAS_SEGURADORA
                    ])
                  "
                >
                  <span class="font-semibold pr-2 pl-2">
                    {{
                      `${
                        perfilHelpers.checkPermission([
                          perfilHelpers.menuFuncionalitiesEnum
                            .GERENCIAR_PROPOSTAS_SEGURADORA
                        ])
                          ? "Editar"
                          : "Visualizar"
                      } informações da Proposta`
                    }}
                  </span>
                </vs-button>
              </router-link>
            </div>
          </div>
        </vs-card>
        <TableSocios
          class="kaban"
          v-if="currentPipe.name === 'PEP'"
          :Socios="
            cardType === 'SEGURADO_QSA_PEP'
              ? socios.InsuredPEPQSAs
              : socios.PEPQSAs
          "
          :TypeText="cardType"
        />
      </vs-tab>

      <vs-tab label="Histórico do Motor">
        <div
          class="vx-row"
          v-if="!regrasExecutadas || regrasExecutadas.length == 0"
        >
          <div class="vx-col w-full mt-2">
            <vs-alert color="warning" class="h-auto">
              Nenhuma regra foi executada nessa proposta.
            </vs-alert>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col pt-5 w-full mt-2">
            <vs-collapse
              type="margin"
              v-for="(regra, index) in regrasExecutadas"
              :key="index"
            >
              <vs-collapse-item>
                <div slot="header">
                  <p class="text-sm font-semibold"></p>
                  <p class="text-lg">
                    {{
                      (regra || {}).EngineExecutionType.Name || "Não informado"
                    }}
                  </p>
                  <p class="text-sm">
                    <b>Executada em </b
                    >{{ (regra || {}).CreateDate | moment("DD/MM/YYYY") }}
                  </p>
                  <!--<vs-button class="status" type="filled"
                          size="small"
                          :color="getLabelColor(regra)">
                          {{getLabelText(regra)}}
                        </vs-button>-->
                </div>

                <div class="vx-row flex">
                  <div class="vx-col flex-1">
                    <vs-table
                      :data="
                        ((regra || {}).RuleEngineResult || {}).RuleResults || []
                      "
                      noDataText="Nenhum histórico de regras executado."
                    >
                      <template slot="thead">
                        <vs-th> Etapa </vs-th>
                        <vs-th> Regra </vs-th>
                        <vs-th> Executada com sucesso? </vs-th>
                        <vs-th> Mensagem </vs-th>
                      </template>

                      <template slot-scope="{ data }">
                        <vs-tr
                          :key="indextr"
                          v-for="(tr, indextr) in data"
                          :style="getRowStyle(data[indextr])"
                        >
                          <vs-td :data="indextr">
                            <div class="flex">
                              <span>{{ indextr + 1 }}</span>
                            </div>
                          </vs-td>
                          <vs-td :data="data[indextr].RuleName">
                            <div class="flex">
                              <span>{{ data[indextr].RuleName }}</span>
                            </div>
                          </vs-td>
                          <vs-td :data="data[indextr].Success">
                            <div class="flex">
                              <span>{{
                                data[indextr].Success ? "Sim" : "Não"
                              }}</span>
                            </div>
                          </vs-td>
                          <vs-td :data="data[indextr].Messages">
                            <div class="flex">
                              <span
                                v-if="
                                  (data[indextr].Messages || []).length == 0
                                "
                              ></span>
                              <div
                                v-if="(data[indextr].Messages || []).length > 0"
                              >
                                <ul>
                                  <li
                                    v-for="(message, indexMsg) in data[indextr]
                                      .Messages || []"
                                    :key="indexMsg"
                                  >
                                    {{ message }}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </div>
      </vs-tab>
      <!-- <vs-tab label="Histórico de Callbacks" class="new-history">
        <div class="vx-row" v-if="!Observations || Observations.length == 0">
          <div class="vx-col w-full mt-2">
            <vs-alert color="warning" class="h-auto">
              Nenhum callback encontrado nessa proposta.
            </vs-alert>
          </div>
        </div>

        <vs-table
          :data="Observations"
          :hoverFlat="true"
          noDataText="Nenhuma registro encontrado"
        >
          <template slot="thead">
            <vs-th>Criado por</vs-th>
            <vs-th>Observação</vs-th>
            <vs-th class="text-left">Data</vs-th>
            <vs-th>Origem</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-td :data="data[indextr].Id" class="text-left ft-12">
                {{ item.CreateByPerson || "-" }}
              </vs-td>

              <vs-td :data="item.Origin" class="text-left ft-12">
                {{ item.Observation }}
              </vs-td>

              <vs-td :data="item.CreateDate" class="text-left ft-12">
                {{ item.CreateDate | moment("DD/MM/YYYY HH:mm") }}
              </vs-td>
               <vs-td :data="item.Origin" class="text-left ft-12">
                {{ item.Source }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-tab> -->

      <template
        v-if="!['TOMADOR_QSA_PEP', 'SEGURADO_QSA_PEP'].includes(cardType)"
      >
        <vs-tab label="Follow Up">
          <div class="vx-row" v-if="observacoes.length == 0">
            <div class="vx-col w-full mt-2">
              <vs-alert color="warning" class="h-auto">
                Nenhum follow up até o momento.
              </vs-alert>
            </div>
          </div>
          <div class="vx-row px-4">
            <vs-card
              class="p-8 pt-4 pb-4 follow-up-card"
              v-for="(mensagem, index) in observacoes"
              :key="index"
            >
              <div class="follow-up-card-content">
                <div class="follow-up-card-content-observation">
                  <h6 class="flex items-baseline">
                    <span class="text-primary mr-2 mb-2">{{
                      mensagem.CreateByPerson
                    }}</span>
                    <small
                      >escreveu em
                      {{
                        new Date(mensagem.CreateDate) | dateFormat("DD/MM/YYYY")
                      }}</small
                    >
                  </h6>
                  <p class="pl-4">{{ mensagem.Observation }}</p>
                </div>
                <div class="follow-up-card-content-source">
                  <span >Origem:</span>
                  <span class="observation-source"
                    ><b>{{ mensagem.Source }}</b>
                  </span>
                </div>
              </div>
            </vs-card>
          </div>
          <div class="vx-row" v-if="hasPermission">
            <div class="vx-col pt-5 w-full mt-2">
              <label>Comentário</label>
              <vs-textarea v-model="observation" />
            </div>
          </div>
          <div class="vx-row" v-if="hasPermission">
            <div class="vx-col text-right w-full mt-2">
              <vs-button
                :disabled="(observation || '').length == 0"
                @click.native="enviarObservacao()"
                color="primary"
              >
                <span class="font-semibold pr-2 pl-2">
                  Enviar para o corretor
                </span>
              </vs-button>
            </div>
          </div>
        </vs-tab>

        <vs-tab label="Documentos Solicitados">
          <div class="vx-row" v-if="documentos.length == 0">
            <div class="vx-col w-full mt-2">
              <vs-alert color="warning" class="h-auto">
                Nenhum documento solicitado para a corretora.
              </vs-alert>
            </div>
          </div>
          <div>
            <div>
              <vs-card
                class="mt-1 p-2"
                v-for="(documento, index) in documentos"
                :key="index"
              >
                <div class="vx-row flex">
                  <div class="vx-col flex-1">
                    <p class="text-sm font-semibold"></p>
                    <p class="text-lg">
                      {{ documento.Name || "Não informado" }}
                    </p>
                    <p class="text-sm pt-4">
                      <b>Status </b>{{ documento.StatusDescription }}
                    </p>
                    <p class="text-sm pt-4">
                      <b>Total de anexos </b>{{ documento.TotalArchives }}
                    </p>
                    <p class="text-sm">
                      <b>Solicitado em </b
                      >{{ documento.CreateDate | moment("DD/MM/YYYY") }}
                    </p>
                  </div>
                  <div class="vx-col">
                    <div class="text-right w-full flex items-center">                      
                      <button
                        class="onpoint-download-simple download-button circle-button"
                        v-if="documento.TotalArchives > 0"
                        @click="downloadDocumento(documento)"                    
                      ></button>
                      <vs-button
                        class="delete"
                        v-if="documento.StatusId == 3 && hasPermission"
                        @click="alterarStatusDocumento(documento, 1)"
                        color="success"
                        type="flat"
                        size="large"
                        icon="thumb_up"
                      ></vs-button>
                      <vs-button
                        class="delete"
                        v-if="documento.StatusId == 3 && hasPermission"
                        @click="alterarStatusDocumento(documento, 4)"
                        color="warning"
                        type="flat"
                        size="large"
                        icon="thumb_down"
                      ></vs-button>

                      <button
                        class="onpoint-trash trash-button circle-button"
                        v-if="hasPermission"
                        @click="deletarDocumento(documento)"                                    
                      ></button>
                    </div>
                  </div>
                  <!---<div class="vx-col w-1/4">
                          <p class="text-sm font-semibold">Data de Solicitação</p>
                          <p class="text-lg">{{ documento.CreateDate  | moment('DD/MM/YYYY') }}</p>
                        </div>
                        <div class="vx-col w-1/4">
                          <p class="text-sm font-semibold">Status</p>
                          <p class="text-lg">{{ documento.StatusDescription }}</p>
                        </div>--->
                </div>
              </vs-card>
            </div>
          </div>
          <div class="vx-row" v-if="hasPermission">
            <div class="vx-col text-right w-full mt-2">
              <vs-button
                @click.native="mostrarListaDeDocumentos()"
                color="primary"
              >
                <span class="font-semibold pr-2 pl-2">
                  Adicionar documentos
                </span>
              </vs-button>
              <vs-button
                class="ml-5"
                :disabled="!possuiDocumentacaoPendente"
                @click.native="
                  enviarParaCorretor(
                    'Solicitação de documentos para o corretor.'
                  )
                "
                color="primary"
              >
                <span class="font-semibold pr-2 pl-2">
                  Enviar para o corretor
                </span>
              </vs-button>
            </div>
          </div>
        </vs-tab>
      </template>

      <template v-if="isMapfre">
        <vs-tab v-if="podeExibirCoCorretagemMapfre" label="Cocorretagem">
          <vs-card class="mt-4 p-8">
            <div class="vx-row">
              <div class="vx-col w-1/2">
                <p class="text-sm font-semibold">Status cocorretagem</p>
                <p class="text-lg">
                  {{
                    proposta.HasCoBrokers
                      ? "Possui cocorretagem"
                      : "Não possui cocorretagem"
                  }}
                </p>
              </div>
              <div class="vx-col w-1/2">
                <p class="text-sm font-semibold">
                  Código do quadro de distribuição
                </p>
                <p class="text-lg">
                  {{
                    cocorretorLider
                      ? cocorretorLider.CodigoQuadroDistribuicao
                      : "-"
                  }}
                </p>
              </div>
            </div>
          </vs-card>

          <div v-if="possuiCocorretorLiderMapfre">
            <vs-card class="mt-4 p-8">
              <div class="vx-row">
                <div class="vx-col w-1/2">
                  <p class="text-lg font-semibold">Cocorretor líder</p>
                </div>
              </div>

              <div class="vx-row mt-8">
                <div class="vx-col w-1/2" :style="{display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems:'flex-start'}">
                  <p class="text-sm font-semibold">Razão social</p>
                  <p class="text-lg">
                    {{ (cocorretorLider.CoBrokerDetail || {}).Name }}
                  </p>
                </div>
                <div class="vx-col w-1/2">
                  <div class="vx-row w-full" :style="{display: 'flex', justifyContent:'space-between', alignItems: 'center'}">
                    <div class="">
                      <p class="text-sm font-semibold">CNPJ</p>
                      <p class="text-lg">
                        {{
                          (cocorretorLider.CoBrokerDetail || {}).CpfCnpj
                            ? (cocorretorLider.CoBrokerDetail || {}).CpfCnpj
                            : "XX.XXX.XXX/XXXX-XX"
                        }}
                      </p>
                    </div>
                    <div class="">
                      <p class="text-sm font-semibold">
                        % participação na cocorretagem
                      </p>
                      <p class="text-lg">
                        {{
                          (cocorretorLider || {})
                            .Rate.toFixed(2)
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </vs-card>
          </div>

          <div v-if="possuiCocorretorMapfre">
            <div v-for="(cocorretor, index) in cocorretores" :key="index">
              <vs-card class="mt-4 p-8">
                <div class="vx-row">
                  <div class="vx-col w-1/2">
                    <p class="text-lg font-semibold">Cocorretor</p>
                  </div>
                </div>

                <div class="vx-row mt-8">
                  <div class="vx-col w-1/2" :style="{display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems:'flex-start'}">
                    <p class="text-sm font-semibold">Razão social</p>
                    <p class="text-lg">
                      {{ (cocorretor.CoBrokerDetail || {}).Name }}
                    </p>
                  </div>
                  <div class="vx-col w-1/2">
                    <div class="vx-row w-full" :style="{display: 'flex', justifyContent:'space-between', alignItems: 'center'}">
                      <div class="">
                        <p class="text-sm font-semibold">CNPJ</p>
                        <p class="text-lg">
                          {{
                            cocorretor.CoBrokerDetail.CpfCnpj
                              ? (cocorretor.CoBrokerDetail || {}).CpfCnpj
                              : "XX.XXX.XXX/XXXX-XX"
                          }}
                        </p>
                      </div>
                      <div class="">
                        <p class="text-sm font-semibold">
                          % participação na cocorretagem
                        </p>
                        <p class="text-lg">
                          {{
                            (cocorretor || {}).Rate.toFixed(2)
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </vs-card>
            </div>
          </div>

          <div class="vx-row mt-6" v-if="cardType === 'PROPOSTA'">
            <div class="vx-col text-right w-full mt-2">
              <router-link
                :to="{
                  name: 'resumo-proposta-visao-seguradora',
                  params: {
                    propostaUniqueId: this.proposta.UniqueId,
                    proposalType: 'Proposta'
                  }
                }"
                target="_blank"
              >
                <vs-button
                  color="primary"
                  v-if="
                    perfilHelpers.checkPermission([
                      perfilHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_PROPOSTAS_SEGURADORA,
                      perfilHelpers.menuFuncionalitiesEnum
                        .VISUALIZAR_PROPOSTAS_SEGURADORA
                    ])
                  "
                >
                  <span class="font-semibold pr-2 pl-2">
                    {{
                      `${
                        perfilHelpers.checkPermission([
                          perfilHelpers.menuFuncionalitiesEnum
                            .GERENCIAR_PROPOSTAS_SEGURADORA
                        ])
                          ? "Editar"
                          : "Visualizar"
                      } informações da Proposta`
                    }}
                  </span>
                </vs-button>
              </router-link>
            </div>
          </div>
        </vs-tab>

        <vs-tab v-if="podeExibirCosseguro" label="Cosseguro">
          <vs-card class="mt-4 p-8">
            <div class="vx-row">
              <div class="vx-col w-1/2">
                <p class="text-sm font-semibold">Tipo de cosseguro</p>
                <p class="text-lg">
                  {{
                    tipoCosseguro(tipoCosseguradora)
                  }}
                </p>
              </div>
            </div>
          </vs-card>

          <div v-if="tipoCosseguradora == 1">
            <div v-for="(cosseguradora, index) in cosseguradoras" :key="index">
              <vs-card class="mt-4 p-8">
                <div class="vx-row">
                  <div class="vx-col w-1/2">
                    <p class="text-lg font-semibold">Cosseguradora</p>
                  </div>
                </div>
                <div class="vx-row mt-8">
                  <div class="vx-col w-2/3" :style="{display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems:'flex-start'}">
                    <p class="text-sm font-semibold">Razão social</p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).CoInsurance.Person.Name) }}
                    </p>
                  </div>
                  <div class="vx-col w-1/3">
                    <p class="text-sm font-semibold">CNPJ</p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).CoInsurance.Person.CpfCnpj) | maskCpfCnpj }}
                    </p>
                  </div>
                </div>
                <div class="vx-row mt-6">
                  <div class="vx-col w-1/4">
                    <p class="text-sm font-semibold">Código cosseguradora</p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).CoInsuranceId) }}
                    </p>
                  </div>
                  <div class="vx-col w-1/4">
                    <p class="text-sm font-semibold">
                      % de gastos
                    </p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).PercentSpanding.toFixed(2)) }}
                    </p>
                  </div>
                  <div class="vx-col w-1/4">
                    <p class="text-sm font-semibold">
                      % de participação
                    </p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).PercentParticipation.toFixed(2)) }}
                    </p>
                  </div>
                  <div class="vx-col w-1/4">
                    <p class="text-sm font-semibold">
                      comissão de cosseguro
                    </p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).Comission.toFixed(2)) }}
                    </p>
                  </div>
                </div>
              </vs-card>
            </div>
          </div>

          <div v-if="tipoCosseguradora == 2">
            <div v-for="(cosseguradora, index) in cosseguradoras" :key="index">
              <vs-card class="mt-4 p-8">
                <div class="vx-row">
                  <div class="vx-col w-1/2">
                    <p class="text-lg font-semibold">Cosseguradora</p>
                  </div>
                </div>
                <div class="vx-row mt-8">
                  <div class="vx-col w-2/3" :style="{display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems:'flex-start'}">
                    <p class="text-sm font-semibold">Razão social</p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).CoInsurance.Person.Name) }}
                    </p>
                  </div>
                  <div class="vx-col w-1/3">
                    <p class="text-sm font-semibold">CNPJ</p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).CoInsurance.Person.CpfCnpj) | maskCpfCnpj }}
                    </p>
                  </div>
                </div>
                <div class="vx-row mt-6">
                  <div class="vx-col w-1/4">
                    <p class="text-sm font-semibold">Código cosseguradora líder</p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).CodeCoInsurance) }}
                    </p>
                  </div>
                  <div class="vx-col w-1/4">
                    <p class="text-sm font-semibold">
                      % de gastos
                    </p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).PercentSpanding.toFixed(2)) }}
                    </p>
                  </div>
                  <div class="vx-col w-1/4">
                    <p class="text-sm font-semibold">
                      % de participação
                    </p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).PercentParticipation.toFixed(2)) }}
                    </p>
                  </div>
                  <div class="vx-col w-1/4">
                    <p class="text-sm font-semibold">
                      % de corretor
                    </p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).PercentBroker.toFixed(2)) }}
                    </p>
                  </div>
                </div>
                <div class="vx-row mt-6">
                  <div class="vx-col w-1/4">
                    <p class="text-sm font-semibold">
                      Identificação (opcional)
                    </p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).NumberIdentity) }}
                    </p>
                  </div>
                  <div class="vx-col w-1/4">
                    <p class="text-sm font-semibold">
                      Data da identificação
                    </p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).IdentityDate)| moment("DD/MM/YYYY HH:mm")  }}
                    </p>
                  </div>
                  <div class="vx-col w-1/4">
                    <p class="text-sm font-semibold">
                      Número apólice líder
                    </p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).LeadPolicyNumber) }}
                    </p>
                  </div>
                  <div class="vx-col w-1/4">
                    <p class="text-sm font-semibold">
                      Endosso
                    </p>
                    <p class="text-lg">
                      {{ tratarCampoVazio((cosseguradora || {}).NumberEndorsement) }}
                    </p>
                  </div>
                </div>
              </vs-card>
            </div>
          </div>

          <div class="vx-row mt-6" v-if="cardType === 'PROPOSTA'">
            <div class="vx-col text-right w-full mt-2">
              <router-link
                :to="{
                  name: 'resumo-proposta-visao-seguradora',
                  params: {
                    propostaUniqueId: this.proposta.UniqueId,
                    proposalType: 'Proposta'
                  }
                }"
                target="_blank"
              >
                <vs-button
                  color="primary"
                  v-if="
                    perfilHelpers.checkPermission([
                      perfilHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_PROPOSTAS_SEGURADORA,
                      perfilHelpers.menuFuncionalitiesEnum
                        .VISUALIZAR_PROPOSTAS_SEGURADORA
                    ])
                  "
                >
                  <span class="font-semibold pr-2 pl-2">
                    {{
                      `${
                        perfilHelpers.checkPermission([
                          perfilHelpers.menuFuncionalitiesEnum
                            .GERENCIAR_PROPOSTAS_SEGURADORA
                        ])
                          ? "Editar"
                          : "Visualizar"
                      } informações da Proposta`
                    }}
                  </span>
                </vs-button>
              </router-link>
            </div>
          </div>
        </vs-tab>

        <vs-tab v-if="podeExibirResseguro"  label="Resseguro">
          <vs-card class="mt-4 p-8">
            <div class="vx-row">
              <div class="vx-col w-1/2">
                <p class="text-sm font-semibold">Tipo de resseguro</p>
                <p class="text-lg">
                  {{
                    proposta.HasReinsurers
                      ? "Facultativo"
                      : "Contrato automático"
                  }}
                </p>
              </div>
            </div>
          </vs-card>

          <div v-if="proposta.HasReinsurers">
            <div v-for="(resseguradora, index) in resseguradoras" :key="index">
              <vs-card class="mt-4 p-8">
                <div class="vx-row">
                  <div class="vx-col w-1/2">
                    <p class="text-lg font-semibold">Resseguradora</p>
                  </div>
                </div>
                <div class="vx-row mt-8">
                  <div class="vx-col w-1/2" :style="{display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems:'flex-start'}">
                    <p class="text-sm font-semibold">Razão social</p>
                    <p class="text-lg">
                      {{ (resseguradora || {}).Reinsurer.Person.Name }}
                    </p>
                  </div>
                  <div class="vx-col w-1/2">
                    <p class="text-sm font-semibold">CNPJ</p>
                    <p class="text-lg">
                      {{ (resseguradora || {}).Reinsurer.Person.CpfCnpj | maskCpfCnpj }}
                    </p>
                  </div>
                </div>
                <div class="vx-row mt-6">
                  <div class="vx-col w-1/3">
                    <p class="text-sm font-semibold">
                      % participação do contrato
                    </p>
                    <p class="text-lg">
                      {{ (resseguradora || {}).Percent.toFixed(2) }}
                    </p>
                  </div>
                  <div class="vx-col w-1/3">
                    <p class="text-sm font-semibold">Número slip</p>
                    <p class="text-lg">
                      {{ (resseguradora || {}).NumberSlip }}
                    </p>
                  </div>
                  <div class="vx-col w-1/3">
                    <p class="text-sm font-semibold">Ano contrato resseguradora</p>
                    <p class="text-lg">
                      {{ (resseguradora || {}).ContractYear }}
                    </p>
                  </div>
                </div>
              </vs-card>
            </div>
          </div>

          <div class="vx-row mt-6" v-if="cardType === 'PROPOSTA'">
            <div class="vx-col text-right w-full mt-2">
              <router-link
                :to="{
                  name: 'resumo-proposta-visao-seguradora',
                  params: {
                    propostaUniqueId: this.proposta.UniqueId,
                    proposalType: 'Proposta'
                  }
                }"
                target="_blank"
              >
                <vs-button
                  color="primary"
                  v-if="
                    perfilHelpers.checkPermission([
                      perfilHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_PROPOSTAS_SEGURADORA,
                      perfilHelpers.menuFuncionalitiesEnum
                        .VISUALIZAR_PROPOSTAS_SEGURADORA
                    ])
                  "
                >
                  <span class="font-semibold pr-2 pl-2">
                    {{
                      `${
                        perfilHelpers.checkPermission([
                          perfilHelpers.menuFuncionalitiesEnum
                            .GERENCIAR_PROPOSTAS_SEGURADORA
                        ])
                          ? "Editar"
                          : "Visualizar"
                      } informações da Proposta`
                    }}
                  </span>
                </vs-button>
              </router-link>
            </div>
          </div>
        </vs-tab>
      </template>
    </vs-tabs>

    <vs-popup
      class="popup-lista-documentos"
      title="Solicitar documentação"
      :active.sync="popupListaDocumentos"
    >
      <vs-tabs color="warning">
        <vs-tab label="Lista de Documentos">
          <div class="vx-row">
            <div class="vx-col w-full">
              <ul class="mt-8" v-if="(documentosPadrao || []).length > 0">
                <li
                  v-for="(documento, index) in documentosPadrao"
                  :key="documento.Id"
                  :class="{ 'pt-5': index > 0 }"
                >
                  <vs-checkbox
                    v-if="documentoJaFoiSolicitado(documento.Name)"
                    v-model="documentosParaSolicitar"
                    :vs-value="documento.Id"
                    >{{ documento.Name }}</vs-checkbox
                  >
                </li>
              </ul>
              <div v-if="(documentosPadrao || []).length == 0">
                <p>Nenhum tipo de documento encontrado!</p>
              </div>
            </div>
          </div>
          <div class="vx-row" v-if="(documentosPadrao || []).length > 0">
            <div class="vx-col pt-5 text-right w-full mt-2">
              <vs-button @click.native="solicitarDocumentos()" color="primary">
                <span class="font-semibold pr-2 pl-2">
                  Solicitar documentação
                </span>
              </vs-button>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Outros documentos">
          <div class="vx-row">
            <div class="vx-col w-full">
              <div
                class="vx-row"
                v-if="(documentosManuaisParaSolicitar || []).length == 0"
              >
                <div class="vx-col w-full mt-2">
                  <vs-alert color="warning" class="h-auto">
                    Nenhum documento adicionado. Clique no botão de adicionar
                    documentos;
                  </vs-alert>
                </div>
              </div>
              <div
                class="vx-row"
                v-for="(documentoManual,
                index) in documentosManuaisParaSolicitar || []"
                :key="index"
              >
                <div class="vx-col md:w-10/12 w-full mt-5 md:pr-2">
                  <vs-input
                    label-placeholder="Documento"
                    size="large"
                    v-model="documentoManual.Name"
                    class="w-full"
                  />
                </div>
                <div
                  class="
                    vx-col
                    md:w-2/12
                    w-full
                    mt-5
                    md:pr-0 md:pl-2
                    p-0
                    flex
                    items-center
                  "
                >                  
                  <button
                    class="onpoint-trash remove-document-button"
                    @click="removeDocumentoManual(index)"                                      
                  ></button>    
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col w-full">
                  <vs-button
                    icon-after
                    @click="adicionaDocumentoManual()"
                    class="font-semibold mt-2 float-right"
                    color="primary"
                    type="flat"
                    icon="add_circle"
                  >
                    Adicionar novo Documento
                  </vs-button>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col pt-5 text-right w-full mt-2">
                  <vs-button
                    @click.native="solicitarDocumentosManuais()"
                    color="primary"
                  >
                    <span class="font-semibold pr-2 pl-2">
                      Solicitar documentação
                    </span>
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </vs-popup>

    <vs-popup
      class="popup-download-arquivos"
      title="Anexos do documento"
      :active.sync="popupListaArquivos"
    >
      <div class="vx-row">
        <div class="vx-col w-full">
          <h5>{{ (documentoSelecionado || {}).Name }}</h5>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col pt-5 w-full mt-2">
          <vs-card
            class="mt-1 p-2"
            v-for="(archive, index) in arquivosParaDownload"
            :key="index"
          >
            <div class="vx-row flex">
              <div class="vx-col flex-1">
                <p class="text-sm font-semibold"></p>
                <p class="text-lg">
                  {{ archive.OriginalFilename || "Não informado" }}
                  {{ archive.Extension }}
                </p>
                <p class="text-sm">
                  <b>Anexado em </b
                  >{{ archive.CreateDate | moment("DD/MM/YYYY") }}
                </p>
              </div>
              <div class="vx-col">
                <div class="text-right w-full flex items-center">
                  <vs-button
                    class="delete"
                    @click="baixarArquivo(archive)"
                    color="primary"
                    type="flat"
                    size="large"
                    icon="get_app"
                  ></vs-button>
                </div>
              </div>
            </div>
          </vs-card>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { kanbanProvider } from "@/providers/kanban.provider";
import { mask, TheMask } from "vue-the-mask";
import { mapMutations, mapActions, mapGetters } from "vuex";
import * as Enums from "@/utils/enums";
import TableSocios from "@/components/vs-table/Table-Socios.vue";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import environment from "../../../environment";

export default {
  name: "ProposalDataViewContent",
  directives: { mask },
  components: { TheMask, TableSocios },
  props: {
    data: {
      type: Object
    },
    cardType: {
      type: String
    },
    socios: {
      type: Object
    },
    cardId: {
      type: String
    },
    permissoesCocorretagemMapfre: {
      type: Array,
      default: function() {
        return [];
      }
    },
    permissoesResseguroMapfre: {
      type: Array,
      default: function() {
        return [];
      }
    },
    permissoesCosseguroMapfre: {
      type: Array,
      default: function() {
        return [];
      }
    },
    stepName: {
      type: String
    },
    cardMovementUser: {
      type: Object,
      default: () => {}
    },
    cardPriorityList: {
      type: Array,
      default: () => []
    },
    priorityDataClickedCard: {
      type: Object,
      default: () => {}
    },
    ownerCard: {
      type: String,
      default: null
    },
    ownerList: {
      type: Array,
      default: () => []
    },
    cardInfos: {
      type: Object,
      default: () => {}
    },
    justificationParameters: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      grupoEconomico: {},
      popupListaDocumentos: false,
      popupListaArquivos: false,
      teveAlteracaoDeValor: false,
      documentoSelecionado: undefined,
      arquivosParaDownload: [],
      documentosManuaisParaSolicitar: [],
      documentosParaSolicitar: [],
      observation: "",
      documentosPadrao: [],
      perfilHelpers: PerfilHelpers,
      Newe: false,
      isEssor: false,
      priorityCard: null,
      selectedPriorityId: null,
      selectedOwnerCard: null,
      isMapfre: false,
      justification: null
    };
  },
  computed: {
    ...mapGetters("pipe-module", { currentPipe: "pipe" }),

    hasPermission() {
      return this.perfilHelpers.checkPermissionKanban(this.currentPipe.id);
    },

    regrasExecutadas: {
      get() {
        const result = (this.data || {}).ProposalEngineExecutionLogs || [];
        return result;
      }
    },
    proposta: {
      get() {
        const result = (this.data || {}).Proposal || {};
        return result;
      }
    },
    Observations: {
      get() {
        const result = (this.data || {}).Observations || {};
        return result;
      }
    },

    pepQsa: {
      get() {
        if (this.cardType === "TOMADOR_QSA_PEP")
          return (this.data || {}).PEPQSAs || {};
        else return (this.data || {}).InsuredPEPQSAs || {};
      }
    },

    documentos: {
      get() {
        return (this.data || {}).RequestDocuments || [];
      }
    },

    possuiDocumentacaoPendente: {
      get() {
        const pendentes = this.documentos.filter(item => {
          return item.StatusId == 2 || item.StatusId == 4;
        });
        return pendentes.length > 0;
      }
    },

    observacoes: {
      get() {
        return (this.data || {}).Observations || [];
      }
    },

    blockInputs: {
      get() {
        return (
          this.proposta.StatusId == Enums.ProposalStatus.AguardandoPagamento ||
          this.proposta.StatusId == Enums.ProposalStatus.Recusada ||
          this.proposta.StatusId == Enums.ProposalStatus.Cancelada ||
          this.proposta.StatusId == Enums.ProposalStatus.Emitida
        );
      }
    },
    carId: {
      get() {
        return this.cardId || {};
      },
      set(val) {
        if (!val) {
          this.data = val;
        }
      }
    },

    podeExibirCoCorretagemMapfre() {
      return this.permissoesCocorretagemMapfre.includes("ExibirCoCorretagem") && this.proposta.HasCoBrokers
    },

    podeExibirResseguro() {
      return this.permissoesResseguroMapfre.includes("ExibirResseguro");
    },

    podeExibirCosseguro() {
      return this.permissoesCosseguroMapfre.includes("ExibirCosseguro");
    },

    possuiCocorretorLiderMapfre() {
      return this.proposta.CoBrokers.some(
        cobroaker => cobroaker.Leader === true
      );
    },

    possuiCocorretorMapfre() {
      return this.proposta.CoBrokers.some(
        cobroaker => cobroaker.Leader === false
      );
    },

    cocorretorLider() {
      return this.proposta.CoBrokers.find(
        cobroaker => cobroaker.Leader === true
      );
    },

    cocorretores() {
      return this.proposta.CoBrokers.filter(
        cobroaker => cobroaker.Leader === false
      );
    },

    resseguradoras() {
      return this.proposta.Reinsurers;
    },

    cosseguradoras() {
      return this.proposta.CoInsurancers;
    },

    tipoCosseguradora(){
      return this.proposta.CoInsurancers.length > 0 ? this.proposta.CoInsurancers[0].CoInsuranceType : null;
    },

    deveExibirResponsavelMovimentacao() {
      return ['Aprovado', 'Reprovado', 'Recusado', 'Cancelado'].includes(this.stepName)
    },

    justificativaMovimentacaoCard() {
      const item = this.cardInfos.cardData.body.metas.find((item) => item.description == "Justificativa");
      return item ? item.value : "";
    },

    justificativaMovimentacaoCardObrigatoria() {
      return this.justificationParameters.IsCardJustificationRequired;
    },

    exibirJustificativaMovimentacaoCard() {
      return this.justificationParameters.CanAddCardJustification;
    },

    podeModificarJustificativaMovimentacaoCard() {
      return this.justificationParameters.StepAllowedToInsertJustification;
    }
  },
  watch: {
    selectedPriorityId(newId) {
      this.priorityCard = this.cardPriorityList.find(option => option.Id === newId);
    },
    priorityCard(newPriority) {
      if (newPriority) {
        this.selectedPriorityId = newPriority.Id;
      }
    },
    ownerCard(newVal) {
      this.selectedOwnerCard = newVal;
    },
    justificativaMovimentacaoCard(newValue){
      this.justification = newValue
    }
  },
  async mounted() {
    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));

    let Newe = parseInt(environment.CompanyId.Newe);
    let Essor = parseInt(environment.CompanyId.Essor);
    let Mapfre = parseInt(environment.CompanyId.Mapfre);
    this.Newe = portalConfig.CompanyId == Newe ? true : false;
    this.isEssor = portalConfig.CompanyId == Essor ? true : false;
    this.isMapfre = portalConfig.CompanyId == Mapfre ? true : false;

    if(this.ownerCard){
      this.selectedOwnerCard = this.ownerCard;
    }

    if (this.priorityDataClickedCard && this.priorityDataClickedCard.Id) {
      this.selectedPriorityId = this.priorityDataClickedCard.Id;
    }   
    
    this.justification = this.justificativaMovimentacaoCard;
  },
  methods: {
    ...mapActions("pipe-module", ["getPipeSteps"]),
    ...mapMutations("pipe-module", ["updateShowCardDataView"]),
    ...mapActions("seguradora-module", [
      "getCardProposalParameterRequestDocument"
    ]),

    async atualizarResponsavel() {
      if (this.selectedOwnerCard != "") {
        await this.$onpoint.loading(async () => {
          return await kanbanProvider.kanbanUpdateCardOwner({
            cardId: this.cardId,
            owner: this.selectedOwnerCard
          })
          .catch(error => {
              this.$onpoint.errorModal(error);
          });
        })
      }
    },

    getLabelText(regra) {
      if (((regra || {}).RuleEngineResult || {}).Success) return "APROVADO";
      else return "REPROVADO";
    },

    getLabelColor(regra) {
      if (((regra || {}).RuleEngineResult || {}).Success) return "success";
      else return "danger";
    },

    getRowStyle(data) {
      if (this.isEssor && this.perfilHelpers.isInsurance()) {
        if (
          data.RuleName === "Sinistros em Andamento" &&
          data.Messages[0] ===
            "Tomador possui sinistros em andamento no Registro Nacional de Sinistros de Crédito e Garantia."
        ) {
          return { backgroundColor: "#e69191" };
        }
      }
      if (data.Success) return { backgroundColor: "#97db9e" };
      else return { backgroundColor: "#e69191" };
    },

    async obterTipoDeDocumentos() {
      await this.$onpoint.loading(async() => {
        return await this.getCardProposalParameterRequestDocument()
          .then(data => {
            this.documentosPadrao = data;
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    salvarGrupoEconomico() {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanSaveEconomicGroup({
            PolicyHolderUniqueId: ((this.proposta || {}).PolicyHolder || {})
              .UniqueId,
            CpfCnpjEconomicGroup: this.grupoEconomico.CpfCnpj
          })
          .then(data => {
            this.$vs.notify({
              title: "Sucesso",
              text:
                "Grupo econômico definido com sucesso. Mova o card para Aprovado para avançar a proposta.",
              color: "success"
            });
            this.$emit("refreshPipe");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    downloadDocumento(documento) {
      this.documentoSelecionado = documento;
      this.arquivosParaDownload = [];
      this.popupListaArquivos = false;

      this.$onpoint.loading(() => {
        return kanbanProvider
          .getRequestDocumentArchives(documento.UniqueId)
          .then(data => {
            this.arquivosParaDownload = data;
            if (this.arquivosParaDownload.length == 1) {
              this.baixarArquivo(this.arquivosParaDownload[0]);
            } else {
              this.popupListaArquivos = true;
            }
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    baixarArquivo(arquivo) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .getArchiveLinkToDownload(arquivo.UniqueId)
          .then(data => {
            window.open(data, "_blank");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    adicionaDocumentoManual() {
      if (!this.documentosManuaisParaSolicitar)
        this.documentosManuaisParaSolicitar = [];

      this.documentosManuaisParaSolicitar.push({ Name: "" });
    },

    removeDocumentoManual(index) {
      this.documentosManuaisParaSolicitar.splice(index, 1);
    },

    mostrarListaDeDocumentos() {
      this.popupListaDocumentos = true;
      this.obterTipoDeDocumentos();
    },

    documentoJaFoiSolicitado(nomeDocumento) {
      const result = this.documentos.some(x => x.Name == nomeDocumento);
      return result == false;
    },

    solicitarDocumentos() {
      const totalSelecionados = (this.documentosParaSolicitar || []).length;
      if (totalSelecionados.length == 0) {
        this.$onpoint.errorModal("Selecione ao menos um documento");
        return;
      }

      const lista = this.documentosPadrao
        .filter(x => this.documentosParaSolicitar.includes(x.Id))
        .map(x => x.Name);

      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanProposalRequestDocument({
            RequestDocuments: lista,
            ProposalUniqueId: this.proposta.UniqueId
          })
          .then(data => {
            this.documentosParaSolicitar = [];
            this.data.RequestDocuments = data.RequestDocuments;
            this.popupListaDocumentos = false;
            this.$onpoint.successModal("Documentos adicionados com sucesso.");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    solicitarDocumentosManuais() {
      const lista = [];
      for (
        let x = 0;
        x < (this.documentosManuaisParaSolicitar || []).length;
        x++
      ) {
        if (
          this.documentosManuaisParaSolicitar[x].Name &&
          this.documentosManuaisParaSolicitar[x].Name.length > 0
        )
          lista.push(this.documentosManuaisParaSolicitar[x].Name);
      }

      if ((lista || []).length == 0) {
        this.$onpoint.errorModal("Adicione ao menos um documento.");
        return;
      }

      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanProposalRequestDocument({
            RequestDocuments: lista,
            ProposalUniqueId: this.proposta.UniqueId
          })
          .then(data => {
            this.documentosManuaisParaSolicitar = [];

            this.data.RequestDocuments = data.RequestDocuments;
            this.popupListaDocumentos = false;
            this.$onpoint.successModal("Documentos adicionados com sucesso.");
            //location.reload();
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    alterarStatusDocumento(documento, status) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanProposalChangeStatusRequestDocument({
            RequestDocumentUniqueId: documento.UniqueId,
            ProposalUniqueId: this.proposta.UniqueId,
            StatusId: status
          })
          .then(data => {
            this.data.RequestDocuments = data.RequestDocuments;
            this.$onpoint.successModal("Operação realizada com sucesso!");
            //location.reload();
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    deletarDocumento(documento) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .kanbanProposalDeleteRequestDocument({
            RequestDocumentUniqueId: documento.UniqueId,
            ProposalUniqueId: this.proposta.UniqueId
          })
          .then(data => {
            this.data.RequestDocuments = data.RequestDocuments;
            this.$onpoint.successModal("Operação realizada com sucesso!");
            //location.reload();
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    async enviarParaCorretor(obs) {
      await this.$onpoint.loading(async () => {
        return await kanbanProvider
          .kanbanProposalSendToBroker({
            Observation: obs || this.observation,
            ProposalUniqueId: this.proposta.UniqueId
          })
          .then(async data => {
            this.$onpoint.successModal("Operação realizada com sucesso!");

            let docs = {
              ProposalUniqueId: this.proposta.UniqueId
            };

            await kanbanProvider.KanbanSendEmailRequestDocumentToBroker(docs);

            this.$emit("refreshPipe");
            //location.reload()
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    enviarObservacao(obs) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .followUpSendObservation({
            Observation: obs || this.observation,
            ProposalUniqueId: this.proposta.UniqueId
          })
          .then(data => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
            this.$emit("refreshPipe");
            //location.reload()
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    async atualizarPrioridade() {
      let data = {
        PipeId: this.priorityDataClickedCard.PipeId,
        CardId: this.priorityDataClickedCard.CardId,
        PipeCardPriority : this.priorityCard.Id
      }

      await this.$onpoint.loading(async () => {
        return await kanbanProvider.updatePriorityKanbanCard(data)
          .then(response => {
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(error => {
            this.$onpoint.errorModal(error);
          });
      })
    },

    tipoCosseguro(typeCoInsurance) {
      if(typeCoInsurance === 0 || typeCoInsurance === null || typeCoInsurance === undefined) {
        return "0 - Isento"
      } else if (typeCoInsurance === 1) {
        return "1 - Cedido"
      } else {
        return "2 - Aceito"
      }
    },

    tratarCampoVazio(campo) {
      return campo ? campo : "-";
    },

    verificarJustificativa(justificativa){
      if(justificativa.trim() !== "") {
        return true;
      } else {
        this.$onpoint.errorModal("Campo não pode conter espaços em branco");
        return
      }
    },

    async salvarJustificativa() {
      if (this.verificarJustificativa(this.justification)) {
        await this.$onpoint.loading(async () => {
          return await kanbanProvider.UpdateKanbanCardJustification({
            cardId: this.cardId,
            Justification: this.justification
          })
          .then(data => {
            this.$vs.notify({
              title: "Sucesso",
              text:
                "Dados salvos com sucesso!",
              color: "success"
            });
            this.$emit("refreshPipe");
          })
          .catch(error => {
              this.$onpoint.errorModal(error);
          });
        })
      }
    }
  }
};
</script>

<style lang="scss">
.popup-lista-documentos {
  z-index: 52900 !important;
}

.popup-download-arquivos {
  z-index: 52900 !important;
}

.popup-editar-proposta {
  z-index: 52900 !important;
}

.quill-container {
  .quill-editor {
    border: 1px solid #cacaca;
    border-radius: 22px;
  }

  .ql-toolbar {
    border: none !important;
  }

  .ql-container {
    border: none !important;
  }
  .ql-disabled > .ql-editor {
    background: #f1f1f1 !important;
    border-radius: 0px 0px 22px 22px !important;
  }
}

.clausulas-particulares-modal.fullscreen {
  .vs-popup {
    width: 100% !important;
  }
}
.ft-12 {
  font-size: 12px !important;
}

.follow-up-card{

  ::v-deep .vs-card--content{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &-content{
    display: flex;
    justify-content: space-between;

    &-observation{
      width: 50%;
    }

    &-source{
      align-self: center;
    }
  }
}

.observation-source {
  background-color: #0f6eb429;
  border-radius: 5em;
  padding: 10px;
  margin: 15px;
  b {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.05px;
    color: #0f6eb4;
  }
}

.trash-button{    
  border:none;
  background-color:transparent; 
  font-size:32px;       
  padding:5px;
    &::before{
      color:red;   
    }

  &:hover{
    background-color:#f0f0f0;
  }
}

.download-button{    
  border:none;
  background-color:transparent; 
  font-size:32px;       
  padding:5px;
    &::before{
      color:blue;   
    }

  &:hover{
    background-color:#f0f0f0;
  }
}

.remove-document-button{
  border:none;
  background-color:transparent; 
  font-size:32px;       
  padding:5px;
  position:relative;
  top:5px;
  &::before{
    color:blue;   
  }

  &:hover{
    background-color:#f0f0f0;
  }
}
</style>
